var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "dialog",
      attrs: { value: _vm.value, persistent: "", width: "1000" }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(_vm._s(_vm.isExistingAd ? _vm.ad.adName : "Create Ad"))
              ]),
              _c("v-spacer")
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-6" },
            [
              _c(
                "v-row",
                { staticClass: "pl-6 pr-6" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Ad Name",
                          "background-color": "#fff",
                          autocomplete: "off",
                          dense: "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.ad.adName,
                          callback: function($$v) {
                            _vm.$set(_vm.ad, "adName", $$v)
                          },
                          expression: "ad.adName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          "prepend-inner-icon": "mdi-calendar",
                          dense: "",
                          outlined: "",
                          label: "Ad Date",
                          autocomplete: "off"
                        },
                        on: { change: _vm.setShipDates },
                        model: {
                          value: _vm.ad.adDate,
                          callback: function($$v) {
                            _vm.$set(_vm.ad, "adDate", $$v)
                          },
                          expression: "ad.adDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "auto-select-first": "",
                          items: _vm.adTypes,
                          "item-text": "description",
                          "item-value": "id",
                          label: "Ad Type",
                          "background-color": "#fff",
                          autocomplete: "off",
                          outlined: "",
                          dense: "",
                          "return-object": ""
                        },
                        model: {
                          value: _vm.ad.adType,
                          callback: function($$v) {
                            _vm.$set(_vm.ad, "adType", $$v)
                          },
                          expression: "ad.adType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pl-6 pr-6" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "auto-select-first": "",
                          items: _vm.adGroups,
                          "item-text": "description",
                          "item-value": "id",
                          label: "Ad Group",
                          "background-color": "#fff",
                          autocomplete: "off",
                          dense: "",
                          outlined: "",
                          "menu-props": { maxHeight: 220 },
                          "return-object": ""
                        },
                        model: {
                          value: _vm.ad.adGroup,
                          callback: function($$v) {
                            _vm.$set(_vm.ad, "adGroup", $$v)
                          },
                          expression: "ad.adGroup"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          label: "1st Ship Date",
                          hint: "M/D/YYYY format",
                          "prepend-inner-icon": "mdi-calendar"
                        },
                        model: {
                          value: _vm.ad.firstShipDate,
                          callback: function($$v) {
                            _vm.$set(_vm.ad, "firstShipDate", $$v)
                          },
                          expression: "ad.firstShipDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          label: "2nd Ship Date",
                          "prepend-inner-icon": "mdi-calendar"
                        },
                        model: {
                          value: _vm.ad.secondShipDate,
                          callback: function($$v) {
                            _vm.$set(_vm.ad, "secondShipDate", $$v)
                          },
                          expression: "ad.secondShipDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "#fafafa" } },
            [
              _c(
                "span",
                [
                  _vm._v(" A2 Ready "),
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color:
                          _vm.selectedAd.readyForUpload === "Y"
                            ? "green"
                            : "red"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.selectedAd.readyForUpload === "Y"
                              ? "mdi-checkbox-marked-circle-outline"
                              : "mdi-close"
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c("v-spacer"),
              _vm.isExistingAd
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { height: "40", color: "#D32F2F" },
                      on: {
                        click: function($event) {
                          _vm.confirmDialog = true
                        }
                      }
                    },
                    [
                      _vm._v(" Delete"),
                      _c(
                        "v-icon",
                        { staticClass: "ml-1", attrs: { dense: "" } },
                        [_vm._v("mdi-trash-can-outline")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "white--text ml-2",
                  attrs: { height: "40", color: "#00B24A" },
                  on: {
                    click: function($event) {
                      _vm.isExistingAd ? _vm.updateAd() : _vm.saveAd()
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.isExistingAd ? "Update" : "Save") + " "
                  ),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.isExistingAd
                          ? "mdi-update"
                          : "mdi-checkbox-marked-circle-outline"
                      )
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ConfirmDialog", {
        attrs: {
          value: _vm.confirmDialog,
          action: _vm.action,
          type: _vm.type,
          remove: _vm.remove
        },
        on: {
          "update:value": function($event) {
            _vm.confirmDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }