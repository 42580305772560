<template>
  <v-dialog :value="value" class="dialog" persistent width="1000">
    <v-card color=#fafafa>
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{isExistingAd ? ad.adName : 'Create Ad'}}</v-toolbar-title>
        <v-spacer />
        <!-- <v-checkbox class="mt-4" color="#2AACE3" label="A2 Ready" true-value="Y" :false-value="ad.readyForUpload === 'null' || null ?  null : null"
          v-model="ad.readyForUpload">
        </v-checkbox> -->
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row class="pl-6 pr-6">
          <v-col cols="6">
            <v-text-field v-model="ad.adName" label="Ad Name" background-color="#fff" autocomplete="off" dense
              outlined />
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="ad.adDate" @change="setShipDates" background-color="#fff" prepend-inner-icon="mdi-calendar" dense outlined
              label="Ad Date" autocomplete="off" >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-autocomplete auto-select-first v-model="ad.adType" :items="adTypes" item-text="description"
              item-value="id" label="Ad Type" background-color="#fff" autocomplete="off" outlined dense return-object/>
          </v-col>
        </v-row>
        <v-row class="pl-6 pr-6">
          <v-col cols="6">
            <v-autocomplete auto-select-first v-model="ad.adGroup" :items="adGroups" item-text="description"
              item-value="id" label="Ad Group" background-color="#fff" autocomplete="off" dense outlined
              :menu-props="{ maxHeight: 220 }" return-object/>
          </v-col>
          <v-col cols="3">
            <v-text-field background-color="#fff" dense outlined label="1st Ship Date" hint="M/D/YYYY format"
              prepend-inner-icon="mdi-calendar" v-model="ad.firstShipDate" />
          </v-col>
          <v-col cols="3">
            <v-text-field background-color="#fff" dense outlined label="2nd Ship Date"
              prepend-inner-icon="mdi-calendar" v-model="ad.secondShipDate" />
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-toolbar flat color="#fafafa">
        <span>
          A2 Ready
          <v-icon :color="selectedAd.readyForUpload === 'Y' ? 'green' : 'red'">
            {{selectedAd.readyForUpload === 'Y' ? 'mdi-checkbox-marked-circle-outline' : 'mdi-close'}}
          </v-icon>
        </span>
        <v-spacer />
        <v-btn v-if="isExistingAd" height="40" color="#D32F2F" class="white--text" @click="confirmDialog = true">
          Delete<v-icon class="ml-1" dense>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn height="40" color="#00B24A" class="white--text ml-2" @click="isExistingAd ? updateAd() : saveAd()">
          {{isExistingAd ? 'Update' : 'Save'}}
          <v-icon right>{{isExistingAd ? 'mdi-update' : 'mdi-checkbox-marked-circle-outline'}}</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>

    <ConfirmDialog :value.sync="confirmDialog" :action="action" :type="type" :remove="remove" />

  </v-dialog>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash'
import { notification } from '@/mixins/notification'

export default {
  name: 'AdEdit',

  mixins: [notification],

  props: ['value', 'selectedAd', 'getAds'],

  components: {
    ConfirmDialog: () => import('@/components/shared/confirm-dialog')
  },

  data () {
    return {
      action: 'Delete',
      adGroups: [],
      adTypes: [],
      confirmDialog: false,
      type: 'Ad'
    }
  },

  computed: {
    ad () {
      return cloneDeep(this.selectedAd)
    },

    isExistingAd () {
      return !isEmpty(this.selectedAd)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getAdOptions()

          if (this.isExistingAd) {
            this.ad.adDate = this.moment(this.ad.adDate).format(this.$config.date_display)
            this.ad.firstShipDate = this.moment(this.ad.firstShipDate).format(this.$config.date_display)
            this.ad.secondShipDate = this.moment(this.ad.secondShipDate).format(this.$config.date_display)
          }
        }
      }
    },

    ad: {
      immediate: true,
      deep: true,
      handler (value) {
        if (value) {
          for (const key in this.ad) {
            if (key && (key === 'adDate' || key === 'firstShipDate' || key === 'secondShipDate')) {
              this.ad[key] = this.moment(this.ad[key]).format(this.$config.date_display)
            }
          }
        }
      }
    }
  },

  methods: {
    async getAdOptions () {
      const adGroups = this.$ads.getAdGroups()
      const adTypes = this.$ads.getAdTypes()

      await Promise.all([adGroups, adTypes])
        .then(response => {
          this.adGroups = response[0].filter(adGroup => adGroup.id !== 'ALL')
          this.adTypes = response[1]
        })
    },

    formatDates (key, value) {
      this.ad[key] = this.moment(value[key]).format(this.$config.date_format)
    },

    setShipDates (date) {
      this.ad.firstShipDate = this.moment(date).subtract(7, 'days').format(this.$config.date_display)
      this.ad.secondShipDate = date
      this.$forceUpdate()
    },

    async getAdGroups () {
      this.$ads.getAdGroups()
        .then(response => {
          this.adGroups = response.filter(adGroup => adGroup.id !== 'ALL')
        })
    },

    getAdTypes () {
      this.$ads.getAdTypes()
        .then(response => {
          this.adTypes = response
        })
    },

    readyForUpload () {
      if (this.ad.readyForUpload === true) {
        this.ad.readyForUpload = 'Y'
      } else {
        this.ad.readyForUpload = null
      }
    },

    allowedAdDates: val => ![1, 2, 3, 4, 5, 6].includes(new Date(val).getDay()),

    async remove () {
      this.ad.adDate = this.moment(this.ad.adDate).format(this.$config.date_format)
      this.ad.firstShipDate = this.moment(this.ad.firstShipDate).format(this.$config.date_format)
      this.ad.secondShipDate = this.moment(this.ad.secondShipDate).format(this.$config.date_format)

      await this.$ads.deleteAd(this.ad)
        .then(() => {
          this.notify('success', 'Ad Successfully Deleted')
          this.$emit('update:value', false)
        }).catch(() => {
          this.notify('error', 'Ad Not Deleted')
        })
      this.getAds()
    },

    async updateAd () {
      this.ad.adDate = this.moment(this.ad.adDate).format(this.$config.date_format)
      this.ad.firstShipDate = this.moment(this.ad.firstShipDate).format(this.$config.date_format)
      this.ad.secondShipDate = this.moment(this.ad.secondShipDate).format(this.$config.date_format)
      await this.$ads.updateAd(this.ad)
        .then(() => {
          this.notify('success', 'Ad Successfully Updated')
          this.$emit('update:value', false)
          this.getAds()
        }).catch(err => {
          this.notify('error', err)
        })
    },

    async saveAd () {
      const ad = {
        adDate: this.moment(this.ad.adDate).format(this.$config.date_format),
        adGroup: { id: this.ad.adGroup.id },
        adNotes: null,
        adType: { id: this.ad.adType.id },
        custAdCode: 'Base',
        adName: this.ad.adName,
        firstShipDate: this.moment(this.ad.firstShipDate).format(this.$config.date_format),
        readyForUpload: null,
        secondShipDate: this.moment(this.ad.secondShipDate).format(this.$config.date_format),
        adLength: 7
      }

      await this.$ads.saveAd(ad)
        .then(response => {
          this.ad.supplierCode = response.supplierId
          this.notify('success', 'Ad Successfully Created')
          this.$emit('update:value', false)
          this.getAds()
        }).catch(err => {
          try {
            this.notify('error', err.response.data)
          } catch {
            this.notify('error', 'Ad Not Created')
          }
        })
    }
  }
}
</script>
